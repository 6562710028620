import React, { useContext } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import Sidebar from '../sidebar/Sidebar';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
const Navigation = React.memo(() => {

  const masterData = useSelector(state => state.masterdata.HomePageData);
  const authStatus = useSelector(state => state.masterdata.authStatus);
  const userData = useSelector(state => state.masterdata.userData);
  const sidebar = useSelector(state => state.masterdata.sidebar);
  const subscription = useSelector(state => state.masterdata.subscription);

  return (
    <>

      <div className="navigation secondaryColor" style={
        {
          zIndex: sidebar ? "10" : "5"
        }
      }>
        <div className='d-flex'>
          <div className='me-3' >
            <Sidebar />
          </div>
          {/* {
            list.map((ele, index) => {
              return (
                <NavLink to={ele.url} key={index} style={{color:"white"}} className="text-decoration-none navigation-menu-item">
                    {ele.name}
                </NavLink>

              )
            })
          } */}
        </div>
        {/* {

          Object.keys(masterData).length > 0 && masterData.hasOwnProperty("subscription") && masterData.subscription.subscr_status == 1 && <div className='subscrib-button'>Your a Vip Member</div>
        }

        {
          (Object.keys(masterData).length > 0 && !masterData.hasOwnProperty("subscription") && authStatus && userData.hasOwnProperty("cust_type") && userData.cust_type == 1) &&
          <NavLink to={"/subscription"} className="text-decoration-none">
           {
            subscription.length> 0 &&
            <div className='subscrib-button'>Subscribe Now</div>
           }
           
          </NavLink>
        } */}




      </div>

    </>

  );
});

export default Navigation;